import React, { useState, useContext } from "react";
import { NumPadContext } from "../../pages/kiosk/index";
import { Row, Col, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
const TextTypes = {
  Number: 0,
  String: 1,
};

const fields = [
  { key: 1, text: 1, type: 0, action: "add" },
  { key: 2, text: 2, type: 0, action: "add" },
  { key: 3, text: 3, type: 0, action: "add" },
  { key: 4, text: 4, type: 0, action: "add" },
  { key: 5, text: 5, type: 0, action: "add" },
  { key: 6, text: 6, type: 0, action: "add" },
  { key: 7, text: 7, type: 0, action: "add" },
  { key: 8, text: 8, type: 0, action: "add" },
  { key: 9, text: 9, type: 0, action: "add" },
  { key: 10, text: <span style={{ color: "#777" }}>Clear</span>, type: 1, action: "clear" },
  { key: 0, text: 0, type: 0, action: "add" },
  { key: 11, text: <FontAwesomeIcon icon={faChevronLeft} size="xs" style={{ color: "#777" }} />, type: 1, action: "delete" },
];

const CredentialInputStyle = {
  width: "100%",
  height: 75,
  boxShadow: "0 3px 6px #00000029",
  borderRadius: 8,
  color: "#333333",
  fontSize: 33,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ActiveInputStyle = {
  border: "4px solid #EFB62A",
};

const InputPlaceholderStyle = {
  fontSize: 33,
  color: "#999",
  fontWeight: 400,
  height: 75,
  display: "flex",
  alignItems: "center",
};

const NumPadBtnStyle = {
  height: 96,
  borderRadius: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#fff",
  fontWeight: 700,
};

const NumberBtnStyle = {
  backgroundColor: "#EFB62A",
  fontSize: 63,
  boxShadow: "0px 3px 10px #EFB62AB3",
};

const StringBtnStyle = {
  backgroundColor: "#CBCBCB99",
  boxShadow: "0px 3px 10px #CBCBCB99",
  fontSize: 33,
};

const NumPad = () => {
  const { phoneNumber, setPhoneNumber, pinCode, setPinCode, currentField, setCurrentField } = useContext(NumPadContext) || {};
  const [pinCodeMask, setPinCodeMask] = useState("");

  const formatPhone = (number) => {
    const cleaned = number.replace(/\D/g, "").substring(0, 10);

    const zip = cleaned.substring(0, 3);
    const middle = cleaned.substring(3, 6);
    const last = cleaned.substring(6, 10);

    let ret = "";
    if (cleaned.length > 6) ret = `(${zip}) ${middle}-${last}`;
    else if (cleaned.length > 3) ret = `(${zip}) ${middle}`;
    else if (cleaned.length > 0) ret = `(${zip}`;
    return ret;
  };

  const formatPin = (pin) => {
    const cleaned = pin.replace(/\D/g, "").substring(0, 10);

    const first3 = cleaned.substring(0, 3);
    const last3 = cleaned.substring(3, 6);

    let ret = "";
    if (cleaned.length > 3) ret = `${first3} ${last3}`;
    else if (cleaned.length > 0) ret = first3;
    return ret;
  };

  return (
    <Row gutter={[0, 10]}>
      <Col span={24} onClick={() => setCurrentField("phone")}>
        <div style={currentField === "phone" ? { ...CredentialInputStyle, ...ActiveInputStyle } : { ...CredentialInputStyle }}>
          {phoneNumber ? phoneNumber : <span style={InputPlaceholderStyle}>Утасны дугаар</span>}
        </div>
      </Col>
      <Col span={24} onClick={() => setCurrentField("pin")}>
        <div style={currentField === "pin" ? { ...CredentialInputStyle, ...ActiveInputStyle } : { ...CredentialInputStyle }}>
          {pinCodeMask ? pinCodeMask : <span style={InputPlaceholderStyle}>Пин код</span>}
        </div>
      </Col>
      <Col span={24}>
        <Row gutter={[12, 12]}>
          {fields.map((field) => {
            return (
              <Col span={8} key={field.key}>
                <Button
                  className="btn-sb w-100 kiosk-btn"
                  style={field.type === TextTypes.Number ? { ...NumPadBtnStyle, ...NumberBtnStyle } : { ...NumPadBtnStyle, ...StringBtnStyle }}
                  onClick={() => {
                    if (!currentField) {
                      setCurrentField("phone");
                      if (field.action === "delete")
                        setPhoneNumber(phoneNumber.slice(0, [2, 11].includes(phoneNumber.length) ? -2 : phoneNumber.length === 7 ? -3 : -1));
                      else if (field.action === "clear") setPhoneNumber("");
                      else if (phoneNumber.length < 14) {
                        const formattedPhone = formatPhone(phoneNumber + field.text);
                        setPhoneNumber(formattedPhone);
                      }
                    }
                    if (currentField === "phone") {
                      if (field.action === "delete")
                        setPhoneNumber(phoneNumber.slice(0, [2, 11].includes(phoneNumber.length) ? -2 : phoneNumber.length === 7 ? -3 : -1));
                      else if (field.action === "clear") setPhoneNumber("");
                      else if (phoneNumber.length < 14) {
                        const formattedPhone = formatPhone(phoneNumber + field.text);
                        setPhoneNumber(formattedPhone);
                      }
                    } else if (currentField === "pin") {
                      if (field.action === "delete") {
                        setPinCode(pinCode.slice(0, pinCode.length === 5 ? -2 : -1));
                        setPinCodeMask(pinCodeMask.slice(0, pinCode.length === 5 ? -2 : -1));
                      } else if (field.action === "clear") {
                        setPinCode("");
                        setPinCodeMask("");
                      } else if (pinCode.length < 7) {
                        const formattedPin = formatPin(pinCode + field.text);
                        setPinCode(formattedPin);
                        setPinCodeMask(formattedPin.replace(/[\S]/g, "•"));
                      }
                    }
                  }}
                >
                  {field.text}
                </Button>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default NumPad;
