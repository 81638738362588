import { faChevronDown, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Modal, Row, Select, Spin } from 'antd'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet'
import authClient from '../../api/auth'
import client from '../../api/client'
import { NumPad } from '../../components/kiosk'
import KioskInfoModal from '../../components/kiosk/info-modal'
import '../../styles/global-version.scss'
// import initReactFastclick from "react-fastclick";

export const NumPadContext = React.createContext()
export default function Login() {
  const [loading, setLoading] = useState(false)
  const [pinCode, setPinCode] = useState('')
  const [banner, setBanner] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [currentField, setCurrentField] = useState(null)
  const [errorModalVisible, setErrorModalVisible] = useState(false)
  const [errorKiosk, setErrorKiosk] = useState('')
  const [errorKioskVisible, setErrorKioskVisible] = useState(false)
  const [sentPinModalVisible, setSentPinModalVisible] = useState(false)
  const [branchOptions, setBranchOptions] = useState([])
  const [selectedBranch, setSelectedBranch] = useState(undefined)
  const [cookie, setCookie] = useCookies(['branchId'])

  useEffect(() => {
    client.get('/api/lookup/banners/active').then(async (res) => {
      if (res.data) {
        setBanner(res.data)
      }
    })
    client.get('/api/lookup/branches').then(async (res) => {
      const branchOptions = res.data.map((el) => {
        return { label: el.address, value: el.id }
      })
      setBranchOptions(branchOptions)
    })
  }, [])

  useEffect(() => {
    setSelectedBranch(parseInt(cookie.branchId) ? parseInt(cookie.branchId) : undefined)
  }, [cookie.branchId])

  const kioskLogin = async () => {
    localStorage.clear()
    setLoading(true)
    if (phoneNumber && pinCode && phoneNumber.length === 14 && selectedBranch) {
      await authClient
        .auth(phoneNumber.replace(/\D/g, '').substring(0, 10), pinCode.replace(/\D/g, ''))
        .then(async (response) => {
          if (response.status === 200) {
            navigate('/kiosk/neworder')
          }
        })
        .catch((error) => {
          console.error(error.message)
          setErrorModalVisible(true)
        })
    } else {
      if (phoneNumber.length < 14) {
        setErrorKiosk('Та утасны дугаараа оруулна уу!')
        setErrorKioskVisible(true)
      } else if (!selectedBranch) {
        setErrorKiosk('Та салбараа сонгоно уу!')
        setErrorKioskVisible(true)
      } else if (!pinCode) {
        setErrorModalVisible(true)
      }
    }
    setLoading(false)
  }

  const getNewPincodeKiosk = async () => {
    if (phoneNumber.length === 14) {
      setLoading(true)
      const phoneNum = phoneNumber.replace(/\D/g, '').substring(0, 10)
      await client
        .post(`/sendnewpincode`, { username: phoneNum })
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  return (
    <div>
      <Helmet title="Нэвтрэх | SB Express" defer={false}>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <style type="text/css">
          {`  
          html,
          body {
            position: fixed;
            overflow: hidden;
          }
        `}
        </style>
      </Helmet>
      <Spin spinning={loading} size="large">
        <div style={{ padding: '24px', height: '100vh' }}>
          <Row gutter={[40, 0]} style={{ height: 'calc(100vh - 48px - 70px)' }}>
            <Col span={12} onClick={() => setCurrentField(null)} style={{ height: '100%' }}>
              <Select
                style={{ width: '100%' }}
                placeholder="Салбараа сонгоно уу"
                className="sb-select mb-2 select-branch-kiosk"
                suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="lg" style={{ color: '#11427F', top: '49%' }} />}
                value={selectedBranch}
                popupClassName="select-branch-kiosk-dropdown"
                options={branchOptions}
                onChange={(e) => {
                  setSelectedBranch(e)
                  setCookie('branchId', e)
                }}
              />
              <div style={{ paddingBottom: '5px', fontSize: 20 }}>Зар/Мэдээ</div>
              <div className="kiosk-ads-content">
                <div style={{ paddingBottom: '12px' }}>
                  <img src={banner?.url} alt="banner" style={{ width: '100%' }} />
                </div>
                <div dangerouslySetInnerHTML={{ __html: banner?.htmlContent }}></div>
              </div>
            </Col>
            <Col span={12}>
              <NumPadContext.Provider
                value={{
                  phoneNumber,
                  pinCode,
                  currentField,
                  setPhoneNumber: (value) => setPhoneNumber(value),
                  setPinCode: (value) => setPinCode(value),
                  setCurrentField: (value) => setCurrentField(value),
                }}
              >
                <NumPad />
              </NumPadContext.Provider>
            </Col>
          </Row>
          <Row gutter={[40, 0]}>
            <Col span={12}>
              <Button
                className="btn-sb btn-sb-primary w-100"
                style={{ height: 70, fontSize: 28 }}
                onClick={() => {
                  navigate('/kiosk/register')
                }}
              >
                Шинээр бүртгүүлэх
              </Button>
            </Col>
            <Col span={12}>
              <Button className="btn-sb btn-sb-primary w-100" style={{ height: 70, fontSize: 28 }} onClick={() => kioskLogin()}>
                Нэвтрэх
              </Button>
            </Col>
          </Row>
        </div>
      </Spin>
      {errorKioskVisible && (
        <KioskInfoModal
          modalVisible={errorKioskVisible}
          closeModal={() => setErrorKioskVisible(false)}
          width={500}
          btnText="Хаах"
          onClick={() => setErrorKioskVisible(false)}
        >
          <span
            style={{
              fontSize: 24,
              fontWeight: 700,
              textAlign: 'center',
              fontFamily: 'tk-futura-pt-n4',
            }}
          >
            {errorKiosk}
          </span>
        </KioskInfoModal>
      )}
      {errorModalVisible && (
        <Modal
          title=""
          open={errorModalVisible}
          onCancel={() => setErrorModalVisible()}
          className="sb-modal sb-modal-kiosk"
          width={500}
          destroyOnClose
          centered
          maskStyle={{ background: '#11427FB9' }}
          footer={[
            <Row justify="center" key={'modal'} gutter={24}>
              <Col span={24}>
                <Button
                  className="btn-sb btn-sb-primary w-100"
                  onClick={() => {
                    setErrorModalVisible(false)
                  }}
                >
                  Буцах
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <div
            style={{
              fontSize: 24,
              fontWeight: 700,
              textAlign: 'center',
              fontFamily: 'tk-futura-pt-n4',
              marginTop: 20,
            }}
          >
            <div>Таны оруулсан утасны дугаар эсвэл</div>
            <div>пин код буруу байна.</div>
            <Button
              className="btn-sb btn-sb-link mt-3"
              style={{ fontWeight: 700, height: 'unset' }}
              onClick={() => {
                getNewPincodeKiosk()
                setErrorModalVisible(false)
                setSentPinModalVisible(true)
              }}
            >
              <FontAwesomeIcon icon={faUnlock} size="xs" className="me-2" />
              Шинэ ПИН КОД авах
            </Button>
          </div>
        </Modal>
      )}
      {sentPinModalVisible && (
        <KioskInfoModal
          modalVisible={sentPinModalVisible}
          closeModal={() => setSentPinModalVisible(false)}
          width={400}
          btnText="Хаах"
          onClick={() => setSentPinModalVisible(false)}
        >
          <span
            style={{
              fontSize: 24,
              fontWeight: 700,
              textAlign: 'center',
              fontFamily: 'tk-futura-pt-n4',
              lineHeight: '30px',
            }}
          >
            <div>Таны {phoneNumber} дугаар руу</div>
            <div>шинэ пин код илгээлээ</div>
          </span>
        </KioskInfoModal>
      )}
    </div>
  )
}
