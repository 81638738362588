import React from 'react'
import { Row, Col, Button, Modal } from 'antd'
const KioskInfoModal = (props) => {
  return (
    <Modal
      title=""
      open={props.modalVisible}
      onCancel={props.closeModal}
      className="sb-modal sb-modal-kiosk"
      width={props.width}
      height={props.height ? props.height : ''}
      destroyOnClose
      centered
      maskStyle={{ background: '#11427FB9' }}
      footer={[
        <Row justify="center" key={'modal'}>
          <Col span={24}>
            <Button className="btn-sb btn-sb-primary w-100" onClick={() => props.onClick()}>
              {props.btnText}
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
          fontSize: 18,
          fontWeight: 400,
        }}
      >
        {props.children}
      </div>
    </Modal>
  )
}

export default KioskInfoModal
